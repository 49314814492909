import React, { useState, useCallback, useEffect } from "react"
import * as Styles from "./index.module.scss"
import backgroundImage01 from "../../images/korea/plan/background01.jpg"
import backgroundImage01Sp from "../../images/korea/plan/background01_sp.jpg"
import backgroundImage02 from "../../images/korea/plan/background02.jpg"
import backgroundImage02Sp from "../../images/korea/plan/background02_sp.jpg"

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{
    width: number | undefined
    height: number | undefined
  }>({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener("resize", handleResize)

    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowSize
}

const TabsComponent = () => {
  //タブの状態をとる
  const [state, setState] = useState({
    tab: "panel1",
  })
  
  const windowSize = useWindowSize()

  //backgroundImageUrlsの型を宣言
  type BackgroundImageUrlsType = {
    [key: string]: string
  }

  // 画面幅に基づいて適切な背景画像を選択
  const backgroundImageUrls: BackgroundImageUrlsType = {
    panel1:
      windowSize.width && windowSize.width <= 767
        ? backgroundImage01Sp
        : backgroundImage01,
    panel2:
      windowSize.width && windowSize.width <= 767
        ? backgroundImage02Sp
        : backgroundImage02,
  }

  // クリックしたときのイベントハンドラー
  const handleTabClick = useCallback(event => {
    // イベント発生源の要素を取得
    const element = event.currentTarget

    // aria-controls 属性の値を取得（タブの名前） ex)"panel1"
    const tabState = element.getAttribute("aria-controls")

    // プロパティーを更新
    setState({
      tab: tabState,
    })
  }, [])

  const backgroundImageUrl = backgroundImageUrls[state.tab]

  return (
    <div
      className={Styles.topPlanInner}
      style={{
        backgroundImage: `url(${backgroundImageUrl})`,
      }}
    >
      <div className={Styles.topPlanContent}>
        <h2 className={`${Styles.topPlanHeading} ${Styles.topHeadingLv2}`}>
          <span className={Styles.en}>Plan</span>
        </h2>
        <div className={Styles.topPlanTabListWrapper}>
          <ul role="tablist" className={`${Styles.topPlanTabList} tab-nav`}>
            <li role="presentation" className={Styles.topPlanTabItem}>
              <button
                role="tab"
                aria-controls="panel1"
                aria-selected={state.tab === "panel1"}
                onClick={handleTabClick}
                type="button"
                className={Styles.topPlanTabButton}
              >
                <span>
                  韓国向け
                  <br className={Styles.spOnly} />
                  マーケティング
                  <br className={Styles.spOnly} />
                  支援プラン
                </span>
              </button>
            </li>
            <li role="presentation" className={Styles.topPlanTabItem}>
              <button
                role="tab"
                aria-controls="panel2"
                aria-selected={state.tab === "panel2"}
                onClick={handleTabClick}
                type="button"
                className={Styles.topPlanTabButton}
              >
                <span>
                  韓国消費者向け
                  <br className={Styles.spOnly} />
                  越境コマース
                  <br className={Styles.spOnly} />
                  支援プラン
                </span>
              </button>
            </li>
          </ul>
        </div>
        <div className={Styles.topPlanListWrapper}>
          <div
            className={Styles.topPlanItem}
            role="tabpanel"
            id="panel1"
            aria-hidden={state.tab !== "panel1"}
          >
            <p className={Styles.topPlanItemText}>
              リサーチ、プロモーションの戦略、
              <br className={Styles.spOnly} />
              コンテンツ制作、SNS運用を
              <br className={Styles.spOnly} />
              プラン&実行！
            </p>
            <div className={Styles.topPlanSubListWrapper}>
              <ol className={Styles.topPlanSubList}>
                <li className={Styles.topPlanSubItem}>
                  <div className={Styles.topPlanSubItemTitleWrapper}>
                    <p className={Styles.topPlanSubItemTitle}>市場リサーチ</p>
                  </div>
                  <p className={Styles.topPlanSubItemText}>
                    韓国現地市場の消費者のトレンドや興味関心、競合などを調査。
                  </p>
                </li>
                <li className={Styles.topPlanSubItem}>
                  <div className={Styles.topPlanSubItemTitleWrapper}>
                    <p className={Styles.topPlanSubItemTitle}>コンテンツ制作</p>
                  </div>

                  <p className={Styles.topPlanSubItemText}>
                    マーケティングやデジタルの経験豊富なメンバーが、高品質な制作水準で、効果的なコンテンツをつくります。
                  </p>
                </li>
                <li className={Styles.topPlanSubItem}>
                  <div className={Styles.topPlanSubItemTitleWrapper}>
                    <p className={Styles.topPlanSubItemTitle}>
                      プロモーションーSNS・広告
                    </p>
                  </div>

                  <p className={Styles.topPlanSubItemText}>
                    ターゲットに合わせたプラットフォームでSNSや広告のプランニング・運用を行います。
                  </p>
                </li>
                <li className={Styles.topPlanSubItem}>
                  <div className={Styles.topPlanSubItemTitleWrapper}>
                    <p className={Styles.topPlanSubItemTitle}>
                      効果分析・施策の策定
                    </p>
                  </div>

                  <p className={Styles.topPlanSubItemText}>
                    実行後のレポートやネクストステップの考案まで担います。
                  </p>
                </li>
              </ol>
            </div>
          </div>
          <div
            className={Styles.topPlanItem}
            role="tabpanel"
            id="panel2"
            aria-hidden={state.tab !== "panel2"}
          >
            <p className={Styles.topPlanItemText}>
              コマースの戦略、サイト構築や
              <br className={Styles.spOnly} />
              モール出展支援、プロモーションを
              <br className={Styles.spOnly} />
              トータルでサポート！
            </p>
            <div className={Styles.topPlanSubListWrapper}>
              <ol className={Styles.topPlanSubList}>
                <li className={Styles.topPlanSubItem}>
                  <div className={Styles.topPlanSubItemTitleWrapper}>
                    <p className={Styles.topPlanSubItemTitle}>市場リサーチ</p>
                  </div>

                  <p className={Styles.topPlanSubItemText}>
                    御社の商品に合わせたターゲットの選定、韓国消費者の動向や興味関心を調査。
                  </p>
                </li>
                <li className={Styles.topPlanSubItem}>
                  <div className={Styles.topPlanSubItemTitleWrapper}>
                    <p className={Styles.topPlanSubItemTitle}>
                      オンラインショップ
                      <br className={Styles.spOnly} />
                      ・コンテンツ制作
                    </p>
                  </div>

                  <p className={Styles.topPlanSubItemText}>
                    EコマースのUI/UX改善を続けてきた知識と経験で、高品質な制作水準でサイトやコンテンツを作ります。
                  </p>
                </li>
                <li className={Styles.topPlanSubItem}>
                  <div className={Styles.topPlanSubItemTitleWrapper}>
                    <p className={Styles.topPlanSubItemTitle}>
                      プロモーションーSNS・広告
                    </p>
                  </div>

                  <p className={Styles.topPlanSubItemText}>
                    ターゲットに合わせたプラットフォームでSNSや広告のプランニング・運用を行います。
                  </p>
                </li>
                <li className={Styles.topPlanSubItem}>
                  <div className={Styles.topPlanSubItemTitleWrapper}>
                    <p className={Styles.topPlanSubItemTitle}>
                      効果分析・施策の策定
                    </p>
                  </div>

                  <p className={Styles.topPlanSubItemText}>
                    実行後のレポートやネクストステップの考案まで担います。
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TabsComponent
